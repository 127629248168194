export const notice = [];
for (let i = 0; i < 15; i++) {
    notice.push({
        id: i,
        noticeTitle: 'Update final exam schedule',
        createdFor: 'G1205',
        createdBy: 'Kazi Shahin',
        createdAt: '12 Jun, 2019',
        type: 'Email',
        branch: 'Dhanmondi',
        status: 'Active',
        views: '129'
   })
}

export const forum = [];
for (let i = 0; i < 15; i++) {
    forum.push({
        id: i,
        forumTopic: 'Registration',
        postIndex: 'How to get register on APICAL',
        createdBy: 'Kazi Shahin',
        createdOn: '12 Jun, 2019',
        createdAt: '12 Jun, 2019',
        status: 'Active',
        views: '129',
        comments: '17'
   })
}

export const suggestion = [];
for (let i = 0; i < 15; i++) {
    suggestion.push({
        id: i,
        suggestionId: 'APQM125',
        qId: 'RMCQ125',
        feedbackGivenBy: 'Cristiano Ronaldo',
        branch: 'Dhanmondi',
        givenOn: '12 Jun, 2019, 6:12pm',
        assignTo: 'Kazi Shahin',
        status: 'Pending',
   })
}

export const feedback = [];
for (let i = 0; i < 15; i++) {
    feedback.push({
        id: i,
        feedbackId: 'APQM125',
        qId: 'RMCQ125',
        feedbackGivenBy: 'Cristiano Ronaldo',
        branch: 'Dhanmondi',
        givenOn: '12 Jun, 2019, 6:12pm',
        assignTo: 'Kazi Shahin',
        status: 'Pending',
   })
}


export const blogSharing = [];
for (let i = 0; i < 15; i++) {
    blogSharing.push({
        id: i,
        blogId: 'APQM125',
        blogIndex: 'What is the importance of listening',
        relatedOn: 'Listening',
        createdAt: '12 Jun, 2019, 6:12pm',
        views: '12,255'
   })
}

